import { AtmsApiClient } from '@amzn/et-console-components';
import I18n from '../setupI18n';

export const publishLatencyMetric = (
  name: string,
  initialTime: Date,
  finalTime: Date,
  numberOfOperations = 1
): void => {
  AtmsApiClient.httpPost('/api/tracker', {
    name: name,
    value: (finalTime.valueOf() - initialTime.valueOf()) / numberOfOperations,
    dimensions: [],
    isLatencyMetric: true,
  }).catch(err => {
    console.warn(`${I18n.t('Failed to publish latency metric')}: ${err}`);
  });
};

export const publishCountMetric = (
  name: string,
  dimensionName: string,
  dimensionValue: string
): void => {
  AtmsApiClient.httpPost('/api/tracker', {
    name: name,
    value: 1,
    dimensions: [
      {
        name: dimensionName,
        value: dimensionValue?.length > 0 ? dimensionValue : 'Unknown',
      },
    ],
  }).catch(err => {
    console.warn(`${I18n.t('Failed to emit metric')}: ${err}`);
  });
};

export const publishErrorMetric = (
  name: string,
  errorMessage: string,
  httpStatusCode?: number
): void => {
  let metricName = name;
  if (httpStatusCode && httpStatusCode >= 400 && httpStatusCode < 500) {
    metricName += '-4xx';
  }
  publishCountMetric(metricName, 'error', errorMessage);
};
