import React, { Fragment, useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  Alert,
  ButtonDropdown,
  CustomDetailEvent,
  ProgressBar,
  Select,
} from '@amzn/awsui-components-react/polaris';
import { Container, PageHeader } from '@amzn/et-polaris-utils';
import { PROJECT_LIST_HELP } from './projectHelpContent';
import { HelpInfoLink } from '../HelpContentRouter';
import { parse, stringify } from 'query-string';
import {
  BackgroundTask,
  CellContents,
  Project,
  ProjectStatus,
  SavedFilter,
  TableColumnDef,
  TablePropertyFilteringOption,
  User,
} from '../types/commonTypes';
import { RouteComponentProps, withRouter } from 'react-router';
import { History, Location } from 'history';
import I18n from '../../setupI18n';
import {
  formatLocaleCode,
  canDo,
  Icon,
  usePref,
  AuthenticatedSession,
  AtmsApiClient,
} from '@amzn/et-console-components';
import { AtmsTable, MainStateBase, NonFilteringQueryApiBase } from '../components/AtmsTable';
import { StandardModal } from '../components/StandardModal';
import { NoWrapCell } from '../../shared/Styled';
import { Tooltip } from '../components/Tooltip';
import { displayTimestamp } from '../../shared/displayTimestamp';
import ProjectScoringStatus from './ProjectScoringStatus';
import { buildGroupId } from '../../shared/scoring';
import 'abort-controller/polyfill';
import { NavLink } from 'react-router-dom';
import useFilterCache from '../useFilterCache';
import { isScoringWorkflow } from '../../shared/scoring';
import { publishCountMetric, publishErrorMetric, publishLatencyMetric } from '../metricHelper';
import { getAppHostConfig } from '@amzn/et-console-components';
import { css } from 'emotion';

const formSpacing = css`
  padding-top: 14px;
  padding-bottom: 14px;
  display: inline-block;
`;

const selectSpacing = css`
  display: inline-block;
  padding-left: 2px;
`;

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const dueInHoursToDisplay = {
  '-1': I18n.t('Overdue'),
  4: I18n.t('4 hours'),
  8: I18n.t('8 hours'),
  24: I18n.t('1 day'),
  72: I18n.t('3 days'),
};
const displayToDueInHours = {};
Object.entries(dueInHoursToDisplay).forEach(([k, v]) => (displayToDueInHours[v] = k));

const timeInHoursToDisplay = {
  24: I18n.t('24 hours'),
  72: I18n.t('3 days'),
  168: I18n.t('7 days'),
  720: I18n.t('30 days'),
};
const displayToTimeInLastHours = {};
Object.entries(timeInHoursToDisplay).forEach(([k, v]) => (displayToTimeInLastHours[v] = k));

const getRoleInfo = (role?: string): { columnOrder: string[]; filteringOrder: string[] } => {
  switch (role) {
    case 'ADMIN':
    case 'PROJECT_MANAGER':
      return {
        columnOrder: [
          'id',
          'name',
          'progressStatus',
          'progress',
          'scoring',
          'created',
          'owner',
          'client',
          'buyer',
          'costCenter',
          'businessUnit',
          'subject',
          'mediaType',
          'status',
          'dateDue',
          'source',
          'target',
          'note',
          'globalId',
          'uid',
          'vendors',
        ],
        filteringOrder: [
          'name',
          'sourceLang',
          'targetLang',
          'owner',
          'status',
          'businessUnit',
          'subject',
          'mediaType',
          'buyer',
          'client',
          'createdInLastHours',
          'dueInHours',
          'autoDeletionInHours',
          'vendors',
        ],
      };
    case 'ADMIN_PROJECT_SHARE': //TODO: find better way (that works with pref storage) for configuring column defaults
      return {
        columnOrder: [
          'id',
          'name',
          'shared',
          'progressStatus',
          'progress',
          'scoring',
          'created',
          'owner',
          'client',
          'buyer',
          'costCenter',
          'businessUnit',
          'subject',
          'mediaType',
          'status',
          'dateDue',
          'source',
          'target',
          'note',
          'globalId',
          'uid',
          'vendors',
        ],
        filteringOrder: [
          'name',
          'sourceLang',
          'targetLang',
          'owner',
          'status',
          'businessUnit',
          'subject',
          'mediaType',
          'buyer',
          'client',
          'createdInLastHours',
          'dueInHours',
          'autoDeletionInHours',
          'vendors',
        ],
      };
    case 'LINGUIST':
    default:
      return {
        columnOrder: ['id', 'name', 'created', 'owner'],
        filteringOrder: ['name', 'createdInLastHours', 'owner'],
      };
  }
};

interface Props extends RouteComponentProps {
  history: History;
  location: Location;
  session: AuthenticatedSession;
  defaultOpenEndedPagination?: boolean;
}

interface FilteringQueryApi {
  businessUnit?: string;
  buyer?: string;
  client?: string;
  createdInLastHours?: number;
  dueInHours?: number;
  mediaType?: string;
  name?: string;
  owner?: string | number;
  status?: string[];
  sourceLang?: string[];
  subject?: string;
  targetLang?: string[];
  autoDeletionInHours?: number;
  vendors?: string[];
}

interface UnmanagedQueryApi {
  listStatus?: string;
  isPostMortemScoring?: string;
}

interface MainState extends MainStateBase<Project, FilteringQueryApi, NonFilteringQueryApiBase> {
  canEdit: boolean;
  canDelete: boolean;
  canCreate: boolean;
  canShare: boolean;
  hasPostMortemScoring: boolean;
  error?: object;
  isChangingStatus: boolean;
  languageCodeList: Array<string>;
  mediaTypeIdMap: Map<string, string>;
  mediaTypeNameMap: Map<string, string>;
  ownerIdMap: Map<number | string, string>;
  ownerUserNameMap: Map<string, number | string>;
  showDeleteModal: boolean;
  subjectIdMap: Map<string, string>;
  subjectNameMap: Map<string, string>;
  user?: User;
  scoringStatus: 'loading' | 'loaded' | 'error';
  unmanagedQuery: UnmanagedQueryApi;
  saveFiltersNonLinguist?: {
    default: SavedFilter[];
    updateRemote: (x: SavedFilter[]) => Promise<void>;
    getRemote: () => Promise<SavedFilter[] | undefined>;
  };
  failedStatusChange?: {
    projectIds: number[];
    status: ProjectStatus;
  };
  vendorIdMap: Map<string, string>;
  vendorNameMap: Map<string, string>;
}

// reducer
const projectListReducer = (state: MainState, action): MainState => {
  switch (action.type) {
    case 'itemSelectionChanged':
      return {
        ...state,
        selectedItems: action.selectedItems,
      };
    case 'sessionChanged':
      return {
        ...state,
        canEdit: action.canEdit,
        canDelete: action.canDelete,
        canCreate: action.canCreate,
        canShare: action.canShare,
        hasPostMortemScoring: action.hasPostMortemScoring,
        user: action.user,
        ownerIdMap: action.ownerIdMap,
        ownerUserNameMap: action.ownerUserNameMap,
      };
    case 'searchChanged':
      return {
        ...state,
        queryLoaded: true,
        filteringQuery: action.filteringQuery,
        nonFilteringQuery: action.nonFilteringQuery,
        unmanagedQuery: action.unmanagedQuery,
      };
    case 'projectsLoading':
      return {
        ...state,
        isLoading: true,
        error: undefined,
        selectedItems: [],
      };
    case 'projectsLoaded':
      return {
        ...state,
        isLoading: false,
        items: action.projects,
        scoringStatus: 'loading',
        totalItems: action.totalProjects,
        pages: action.pages,
        ownerIdMap: action.ownerIdMap,
        ownerUserNameMap: action.ownerUserNameMap,
      };
    case 'projectsLoadFailed':
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case 'showDeleteModalChanged':
      return {
        ...state,
        showDeleteModal: action.showDeleteModal,
      };
    case 'mediaTypesLoaded':
      return {
        ...state,
        mediaTypeIdMap: action.mediaTypeIdMap,
        mediaTypeNameMap: action.mediaTypeNameMap,
      };
    case 'mediaTypesLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'subjectsLoaded':
      return {
        ...state,
        subjectIdMap: action.subjectIdMap,
        subjectNameMap: action.subjectNameMap,
      };
    case 'subjectsLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'activeLanguageLoaded':
      return {
        ...state,
        languageCodeList: action.languageCodeList,
      };
    case 'activeLanguageLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    case 'statusChangeStarted':
      return {
        ...state,
        isChangingStatus: true,
        failedStatusChange: undefined,
        error: undefined,
      };
    case 'statusChangeFinished':
      return {
        ...state,
        isChangingStatus: false,
      };
    case 'statusChangeFailed':
      return {
        ...state,
        isChangingStatus: false,
        error: action.error,
        failedStatusChange: action.failedStatusChange,
      };
    case 'projectScoringLoaded':
      return {
        ...state,
        items: action.projects,
        scoringStatus: 'loaded',
      };
    case 'projectScoringFailed':
      return {
        ...state,
        scoringStatus: 'error',
        error: action.error,
      };
    case 'vendorsLoaded':
      return {
        ...state,
        vendorIdMap: action.vendorIdMap,
        vendorNameMap: action.vendorNameMap,
      };
    case 'vendorsLoadFailed':
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

const init = (): MainState => {
  return {
    isLoading: true,
    isChangingStatus: false,
    items: [],
    selectedItems: [],
    pages: 0,
    pageSize: 50,
    totalItems: 0,
    showDeleteModal: false,
    queryLoaded: false,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    canShare: false,
    hasPostMortemScoring: false,
    ownerIdMap: new Map(),
    ownerUserNameMap: new Map(),
    mediaTypeIdMap: new Map(),
    mediaTypeNameMap: new Map(),
    subjectIdMap: new Map(),
    subjectNameMap: new Map(),
    languageCodeList: [],
    filteringQuery: {},
    nonFilteringQuery: { page: 0 },
    scoringStatus: 'loading',
    unmanagedQuery: { isPostMortemScoring: 'false' },
    saveFiltersNonLinguist: {
      default: [
        { text: I18n.t('All'), url: '?' },
        {
          text: I18n.t('In progress'),
          url: '?status=NEW&status=ASSIGNED&status=ACCEPTED_BY_VENDOR',
        },
        { text: I18n.t('Overdue'), url: '?dueInHours=-1' },
        { text: I18n.t('My projects'), url: '?owner=myself' },
      ],
      updateRemote: (filters: SavedFilter[]): Promise<void> => {
        return AtmsApiClient.httpPut('/api/projectFilter', {
          filters: filters,
        });
      },
      getRemote: (): Promise<SavedFilter[] | undefined> => {
        return AtmsApiClient.httpGet('/api/projectFilter');
      },
    },
    vendorIdMap: new Map(),
    vendorNameMap: new Map(),
  };
};

export const ProjectList = withRouter(
  ({ session, history, location, defaultOpenEndedPagination }: Props) => {
    const [prefs] = usePref('projectListPrefs', {});
    const [filterCache, setFilterCache] = useFilterCache('user' in session && session.user.id);
    // TODO: combine page size and pagination type values under projectListPrefs.
    const [openEndedPagination, setOpenEndedPagination] = usePref(
      'projectListOpenEndedPagination',
      defaultOpenEndedPagination ?? true
    );
    const { search } = location;

    //useReducer hook
    const [state, dispatch] = useReducer(projectListReducer, null, init);

    // destructuring
    const {
      error,
      selectedItems,
      showDeleteModal,
      queryLoaded,
      canEdit,
      canDelete,
      canCreate,
      canShare,
      hasPostMortemScoring,
      ownerIdMap,
      ownerUserNameMap,
      mediaTypeIdMap,
      mediaTypeNameMap,
      subjectIdMap,
      subjectNameMap,
      languageCodeList,
      user,
      filteringQuery,
      nonFilteringQuery,
      unmanagedQuery,
      isChangingStatus,
      items,
      isLoading,
      scoringStatus,
      failedStatusChange,
      vendorIdMap,
      vendorNameMap,
    } = state;

    const {
      name,
      client,
      buyer,
      businessUnit,
      status,
      targetLang,
      dueInHours,
      createdInLastHours,
      sourceLang,
      owner,
      subject,
      mediaType,
      autoDeletionInHours,
      vendors,
    } = filteringQuery;

    const { listStatus, isPostMortemScoring } = unmanagedQuery;

    const { page, sortField, sortOrder } = nonFilteringQuery;

    const role = user
      ? (user.role === 'ADMIN' || user.role === 'PROJECT_MANAGER') && canShare
        ? 'ADMIN_PROJECT_SHARE'
        : user.role
      : undefined;

    const saveFilters =
      state.saveFiltersNonLinguist && user
        ? user.role === 'LINGUIST'
          ? { ...state.saveFiltersNonLinguist, default: [] }
          : state.saveFiltersNonLinguist
        : state.saveFiltersNonLinguist;

    // useCallback hooks
    const searchToState = useCallback((): [
      FilteringQueryApi,
      NonFilteringQueryApiBase,
      UnmanagedQueryApi
    ] => {
      const query = parse(search, { parseNumbers: true });
      return [
        {
          name: query.name as string,
          client: query.client as string,
          buyer: query.buyer as string,
          businessUnit: query.businessUnit as string,
          owner: typeof query.owner === 'number' ? query.owner : (query.owner as string),
          status: typeof query.status === 'string' ? [query.status] : (query.status as string[]),
          targetLang:
            typeof query.targetLang === 'string'
              ? [query.targetLang]
              : (query.targetLang as string[]),
          sourceLang:
            typeof query.sourceLang === 'string'
              ? [query.sourceLang]
              : (query.sourceLang as string[]),
          dueInHours: query.dueInHours as number,
          createdInLastHours: query.createdInLastHours as number,
          subject: query.subject as string,
          mediaType: query.mediaType as string,
          autoDeletionInHours: query.autoDeletionInHours as number,
          vendors:
            typeof query.vendors === 'string' ? [query.vendors] : (query.vendors as string[]),
        },
        {
          page: (query.page as number) || 0,
          sortField: query.sortField as string,
          sortOrder: query.sortOrder as string,
        },
        {
          listStatus: query.listStatus as string,
          isPostMortemScoring: query.isPostMortemScoring as string,
        },
      ];
    }, [search]);

    const loadProjects = useCallback(
      async (abortController = undefined) => {
        dispatch({
          type: 'projectsLoading',
        });
        const initialTime = new Date();

        const query = {
          businessUnit,
          buyer,
          client,
          createdInLastHours,
          dueInHours,
          autoDeletionInHours,
          name,
          owner,
          page,
          sourceLang,
          status,
          jobStatus: listStatus,
          targetLang,
          subject,
          mediaType,
          withPagination: !openEndedPagination,
          sortField,
          sortOrder,
          vendor: vendors,
          abbreviated: true,
          isPostMortemScoring,
        };

        //default non true/false values to false
        if (isPostMortemScoring === 'true') {
          query.isPostMortemScoring = 'true';
        } else {
          query.isPostMortemScoring = 'false';
        }

        if (typeof owner !== 'number') {
          if (owner === 'myself' && user) {
            query.owner = user.id;
          } else {
            query.owner = undefined;
          }
        }

        if (prefs && role && prefs[role]) {
          prefs[role].forEach(column => {
            // Add vendor as extendedDomains query param only if it is visible
            if (column.id === 'vendors' && (column.visible !== undefined ? column.visible : true)) {
              query['extendedDomains'] = 'Vendor';
            }
          });
        }

        const getPageCountForOpenEndedPageLoad = (response, query): number => {
          const getPageCount = (response, query): number => {
            // We will show the next page pointer only if the response list has 50 records.
            // This is done since project list api starts from 0 but the page list on the table starts from 1.
            // Thus, for query.page=0 (with 50 records) => Page pointer 1 and 2 will be shown.
            // Moreover, for any page if we have less than 50 records we will consider that there are no more projects left to be shown and we will not show the next page pointer.
            // Change hardcoded 50 if we ever plan to give page size options to users.
            return query.page >= 0 && response?.length === 50 ? query.page + 2 : query.page + 1;
          };
          return query?.page !== undefined ? getPageCount(response, query) : 1;
        };

        try {
          const options = abortController ? { signal: abortController.signal } : undefined;
          const response = await AtmsApiClient.httpGet(
            `/api/project/list?extendedDomains=ProjectWorkflowStep&${stringify(query)}`,
            {},
            options
          );
          // Check if the particular flow is aborted. Helps to chase down race condition.
          if (abortController?.signal?.aborted) return;

          const projects: Project[] = query.withPagination ? response.projects : response;
          projects.forEach(v => {
            if (v.owner.userName && user && v.owner.id !== user.id) {
              ownerIdMap.set(v.owner.id, v.owner.userName);
              ownerUserNameMap.set(v.owner.userName, v.owner.id);
            }
          });
          const pages = query.withPagination
            ? response.paging.pages
            : getPageCountForOpenEndedPageLoad(response, query);
          const totalProjects = query.withPagination ? response.paging.total : 0;
          dispatch({
            type: 'projectsLoaded',
            projects,
            totalProjects: totalProjects,
            pages: pages,
            ownerIdMap,
            ownerUserNameMap,
          });
        } catch (e) {
          publishErrorMetric('projectsLoadFailed-ProjectList', e.message, e.status || 500);
          if (abortController?.signal?.aborted) return;
          dispatch({
            type: 'projectsLoadFailed',
            error: I18n.t('Failed to load projects: %{error}', { error: e }),
          });
        } finally {
          const finalTime = new Date();
          publishLatencyMetric('ProjectListPage-Latency', initialTime, finalTime);
        }
      },
      [
        businessUnit,
        buyer,
        client,
        createdInLastHours,
        dueInHours,
        autoDeletionInHours,
        name,
        owner,
        page,
        sourceLang,
        status,
        listStatus,
        targetLang,
        sortField,
        sortOrder,
        user,
        subject,
        mediaType,
        ownerIdMap,
        ownerUserNameMap,
        vendors,
        openEndedPagination,
        isPostMortemScoring,
      ]
    );

    const loadMediaTypes = useCallback(async () => {
      dispatch({ type: 'mediaTypesLoading' });
      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/mediaType/list`
        );
        const newMediaTypeIdMap = new Map<string, string>();
        const newMediaTypeNameMap = new Map<string, string>();
        response.forEach(v => {
          newMediaTypeIdMap.set(v.id, v.name);
          newMediaTypeNameMap.set(v.name, v.id);
        });
        dispatch({
          type: 'mediaTypesLoaded',
          mediaTypeIdMap: newMediaTypeIdMap,
          mediaTypeNameMap: newMediaTypeNameMap,
        });
      } catch (e) {
        publishErrorMetric('mediaTypesLoadFailed-ProjectList', e.message, e.status || 500);
        dispatch({
          type: 'mediaTypesLoadFailed',
          error: I18n.t('Failed to load media types: %{error}', { error: e }),
        });
      }
    }, []);

    const loadSubjects = useCallback(async () => {
      dispatch({ type: 'subjectsLoading' });
      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/subject/list`
        );
        const newSubjectIdMap = new Map<string, string>();
        const newSubjectNameMap = new Map<string, string>();
        response.forEach(v => {
          newSubjectIdMap.set(v.id, v.name);
          newSubjectNameMap.set(v.name, v.id);
        });
        dispatch({
          type: 'subjectsLoaded',
          subjectIdMap: newSubjectIdMap,
          subjectNameMap: newSubjectNameMap,
        });
      } catch (e) {
        publishErrorMetric('subjectsLoadFailed-ProjectList', e.message, e.status || 500);
        dispatch({
          type: 'subjectsLoadFailed',
          error: I18n.t('Failed to load subjects: %{error}', { error: e }),
        });
      }
    }, []);

    const loadActiveLanguages = useCallback(async () => {
      dispatch({ type: 'activeLanguageLoading' });
      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/language/listActiveLangs`
        );
        const newLanguageCodeSet = new Set<string>();
        response.forEach(v => {
          newLanguageCodeSet.add(v.code);
        });
        const sortedLanguageCodes = [...newLanguageCodeSet].sort();
        dispatch({
          type: 'activeLanguageLoaded',
          languageCodeList: sortedLanguageCodes,
        });
      } catch (e) {
        publishErrorMetric('activeLanguageLoadFailed-ProjectList', e.message, e.status || 500);
        dispatch({
          type: 'activeLanguageLoadFailed',
          error: I18n.t('Failed to load language codes: %{error}', { error: e }),
        });
      }
    }, []);

    const loadVendors = useCallback(async () => {
      dispatch({ type: 'vendorsLoading' });
      try {
        const response = await AtmsApiClient.httpGet(
          `//${WEB_HOST_AND_PORT}/web/api/v9/vendor/list`,
          {},
          { cache: 'no-store' }
        );
        const vendorIdMap = new Map<string, string>();
        const vendorNameMap = new Map<string, string>();
        response.forEach(v => {
          vendorIdMap.set(v.id, v.name);
          vendorNameMap.set(v.name, v.id);
        });
        dispatch({
          type: 'vendorsLoaded',
          vendorIdMap: vendorIdMap,
          vendorNameMap: vendorNameMap,
        });
      } catch (e) {
        // Firefox returns 403 for the vendor list call in certain cases (which is also the reason for the
        // cache:'no-store' above) or it returns "You need to enable JavaScript to run this app." which throws
        // TypeError. The short term fix is to ignore until it can be researched further since it doesn't
        // seem to be a problem in Chrome
        if (!(e && (e instanceof TypeError || e.status === 403))) {
          publishCountMetric('vendorsLoadFailed-ProjectList', 'error', e.message);
          dispatch({
            type: 'vendorsLoadFailed',
            error: I18n.t('Failed to load vendors: %{error}', { error: e }),
          });
        }
      }
    }, []);

    const loadScoringData = useCallback(
      async abortController => {
        if (items.length && !isLoading) {
          const groupIdToProjectId = new Map();
          const projectIdToGroup = new Map();

          items.forEach(project => {
            project.workflowSteps.forEach(workflow => {
              if (isScoringWorkflow(workflow.type)) {
                groupIdToProjectId.set(
                  buildGroupId({ Project: project, WorkflowStep: workflow }),
                  project.id
                );
              }
            });
          });

          try {
            const response = await AtmsApiClient.httpGet(
              `/api/scoring/bulkGetGroups?${stringify({
                groupIds: [...groupIdToProjectId.keys()],
              })}`,
              {},
              { signal: abortController.signal }
            );
            // Check if the particular flow is aborted. Helps to avoid race condition.
            if (abortController.signal.aborted) return;

            response.forEach(groups => {
              if (groupIdToProjectId.has(groups.id)) {
                const projectGroup = projectIdToGroup.get(groupIdToProjectId.get(groups.id));
                projectIdToGroup.set(
                  groupIdToProjectId.get(groups.id),
                  projectGroup ? projectGroup.push(groups) : [groups]
                );
              }
            });

            const newItems = items.map(project => ({
              ...project,
              scoring: projectIdToGroup.get(project.id),
            }));

            dispatch({
              type: 'projectScoringLoaded',
              projects: newItems,
            });
          } catch (e) {
            if (abortController?.signal?.aborted) return;
            publishCountMetric('projectScoringFailed-ProjectList', 'error', e.message);
            dispatch({
              type: 'projectScoringFailed',
              error: I18n.t('Failed to load scoring data: %{error}', { error: e }),
            });
          }
        }
      },
      [isLoading, items]
    );

    // useEffect hooks
    useEffect(() => {
      const user = session.user;

      ownerIdMap.clear();
      ownerUserNameMap.clear();
      if (user) {
        const u = user.userName;
        const myself = `${u ? u : ''}${u ? ' (' : ''}${I18n.t('Myself')}${u ? ')' : ''}`;
        ownerIdMap.set('myself', myself);
        ownerUserNameMap.set(myself, 'myself');
      }

      AtmsApiClient.httpGet(`/api/postMortemScoringSettings`)
        .then(response => {
          dispatch({
            type: 'sessionChanged',
            canEdit: canDo(session, 'project:editOther'),
            canDelete: canDo(session, 'project:deleteOther'),
            canCreate: canDo(session, 'project:create'),
            hasPostMortemScoring: response.enabled,
            canShare: user && user.disableProjectSharing === false,
            user,
            ownerIdMap,
            ownerUserNameMap,
          });
        })
        .catch(() => {
          dispatch({
            type: 'sessionChanged',
            canEdit: canDo(session, 'project:editOther'),
            canDelete: canDo(session, 'project:deleteOther'),
            canCreate: canDo(session, 'project:create'),
            hasPostMortemScoring: false,
            canShare: user && user.disableProjectSharing === false,
            user,
            ownerIdMap,
            ownerUserNameMap,
          });
        });
    }, [session, ownerIdMap, ownerUserNameMap]);

    useEffect(() => {
      if (filterCache && !search) {
        history.replace({ ...location, search: filterCache });
      }
    }, [history, filterCache, search, location]);

    useEffect(() => {
      const [filteringQuery, nonFilteringQuery, unmanagedQuery] = searchToState();
      dispatch({ type: 'searchChanged', filteringQuery, nonFilteringQuery, unmanagedQuery });
      setFilterCache(stringify({ ...filteringQuery, ...nonFilteringQuery }));
    }, [searchToState, setFilterCache]);

    useEffect(() => {
      // If you click on any sort column multiple times there could be race condition due to which the table might show incorrect values.
      // Create the current flows's abort controller. This will help in resolving the race condition.
      const abortController = new AbortController();
      if (queryLoaded && user) {
        loadProjects(abortController);
      }
      // Clean up function.
      // This will make sure the existing flow is stopped when there is a change in dependency.
      return (): void => {
        abortController.abort();
      };
    }, [loadProjects, queryLoaded, user]);

    // Load on initial mount only.
    useEffect(() => {
      document.title = 'Projects - Atms';
      loadMediaTypes();
      loadSubjects();
      loadActiveLanguages();
    }, [loadActiveLanguages, loadSubjects, loadMediaTypes]);

    useEffect(() => {
      // Create the current flows's abort controller. This will help in resolving the race condition.
      const abortController = new AbortController();
      if (!error && !isLoading && prefs && role && prefs[role] && scoringStatus === 'loading') {
        prefs[role].forEach(column => {
          // Load scoring data data only when
          // * the project list is loaded
          // * scoring column is visible
          if (column.id === 'scoring' && (column.visible !== undefined ? column.visible : true)) {
            // Create the current request's abort controller
            loadScoringData(abortController);
          }
        });
      }
      // Clean up function
      // This will make sure the existing flow is stopped when there is a change in dependency.
      return (): void => {
        abortController.abort();
      };
    }, [isLoading, error, prefs, role, loadScoringData, scoringStatus]);

    useEffect(() => {
      if (role && role !== 'LINGUIST') {
        loadVendors();
      }
    }, [role]);

    // handlers
    const handleChangeStatusClick = async (
      e: CustomDetailEvent<ButtonDropdown.ItemClick>
    ): Promise<void> => {
      let status: string | undefined;
      switch (e.detail.id) {
        case 'change-status-completed':
          status = 'COMPLETED';
          break;
        case 'change-status-cancelled':
          status = 'CANCELLED';
          break;
        case 'change-status-new':
          status = 'NEW';
          break;
        default:
          publishCountMetric(
            'statusChangeFailed-ProjectList',
            'error',
            'Failed to change status; invalid status: ' + status
          );
          dispatch({
            type: 'statusChangeFailed',
            error: I18n.t('Failed to change status; invalid status %{status}', {
              status: e.detail.id,
            }),
          });
          return;
      }

      const projectIds = selectedItems.map(p => p.uid);

      await handleChangeStatus(projectIds, status);
    };
    const handleViewProjectTypeChange = useCallback(
      event => {
        let postMortemScoring = '';
        switch (event.detail.selectedOption.id) {
          case 'post-mortem-scoring-projects':
            postMortemScoring = 'true';
            break;
          case 'regular-projects':
            postMortemScoring = 'false';
            break;
        }
        const query = {
          ...unmanagedQuery,
          ...filteringQuery,
          ...nonFilteringQuery,
          isPostMortemScoring: postMortemScoring,
        };
        history.push({ ...location, search: stringify(query) });
      },
      [filteringQuery, history, location, nonFilteringQuery, unmanagedQuery]
    );

    const handleChangeStatus = async (
      projectIds: string[] | number[],
      status: string
    ): Promise<void> => {
      dispatch({ type: 'statusChangeStarted' });

      const query = {
        uid: projectIds,
        status,
      };

      try {
        const backgroundTask: BackgroundTask = await AtmsApiClient.httpPost(
          `/api/project/setStatusAsync?${stringify(query)}`
        );
        await awaitBackgroundTask(backgroundTask);
        dispatch({ type: 'statusChangeFinished' });
      } catch (e) {
        try {
          dispatch({
            type: 'statusChangeFailed',
            failedStatusChange: {
              projectIds: JSON.parse(e.reason).failedProjectIds,
              status: status,
            },
          });
        } catch {
          publishCountMetric('statusChangeFailed-ProjectList', 'error', e.message);
          console.warn(`Couldn't parse exception ${e} as JSON`);
          dispatch({
            type: 'statusChangeFailed',
            error: I18n.t('Failed to change status: %{error}', { error: e }),
          });
        }
      }
      await loadProjects();
    };

    const awaitBackgroundTask = (inputBackgroundTask: BackgroundTask): Promise<BackgroundTask> => {
      return new Promise<BackgroundTask>((resolve, reject) => {
        if (inputBackgroundTask.status === 'OK') {
          resolve(inputBackgroundTask);
        } else if (inputBackgroundTask.status === 'ERROR') {
          reject(inputBackgroundTask);
        } else {
          setTimeout(() => {
            AtmsApiClient.httpGet(
              `/api/backgroundTask?${stringify({ id: inputBackgroundTask.id })}`
            ).then((backgroundTask: BackgroundTask) => {
              resolve(awaitBackgroundTask(backgroundTask));
            });
          }, 1000);
        }
      });
    };

    const handleShareButtonClick = (): void => {
      const query = {
        projectSelection: selectedItems.map(p => p.uid),
      };

      history.push(`/web/project/batchAssignVendor?${stringify(query)}`);
    };

    const handleDeleteClick = (): void => {
      dispatch({
        type: 'showDeleteModalChanged',
        showDeleteModal: true,
      });
    };

    const handleDeleteModalCancelButtonClick = (): void => {
      dispatch({
        type: 'showDeleteModalChanged',
        showDeleteModal: false,
      });
    };

    const handleDeleteModalOkButtonClick = async (): Promise<void> => {
      dispatch({
        type: 'showDeleteModalChanged',
        showDeleteModal: false,
      });

      const query = {
        uid: selectedItems.map(p => p.uid),
      };

      try {
        await AtmsApiClient.httpDelete(`/api/project?${stringify(query)}`);
        await loadProjects();
      } catch (e) {
        publishCountMetric('projectsDeleteFailed-ProjectList', 'error', e.message);
        dispatch({
          type: 'projectsDeleteFailed',
          error: I18n.t('Failed to delete project(s): %{error}', { error: e }),
        });
      }
    };

    const statusToDisplay = useMemo(() => {
      const statusToDisplayMap = {
        NEW: I18n.t('New'),
        ASSIGNED: I18n.t('Assigned'),
        COMPLETED: I18n.t('Completed'),
        CANCELLED: I18n.t('Cancelled'),
      };
      if (canShare) {
        statusToDisplayMap['ACCEPTED_BY_VENDOR'] = I18n.t('Accepted by vendor');
        statusToDisplayMap['DECLINED_BY_VENDOR'] = I18n.t('Declined by vendor');
        statusToDisplayMap['COMPLETED_BY_VENDOR'] = I18n.t('Completed by vendor');
      }
      return statusToDisplayMap;
    }, [canShare]);
    const displayToStatus = useMemo(() => {
      const displayToStatusMap = {};
      Object.entries(statusToDisplay).map(([k, v]) => (displayToStatusMap[v] = k));
      return displayToStatusMap;
    }, [statusToDisplay]);

    const tz = session.user.timezone || 'UTC';

    const columnMap: Map<string, TableColumnDef<Project>> = useMemo(
      () =>
        new Map([
          [
            'id',
            {
              id: 'id',
              header: '#',
              cell: (item): CellContents => item.internalId,
              canSort: true,
              canReorder: false,
              minWidth: '80px',
              width: 80,
            },
          ],
          [
            'shared',
            {
              id: 'shared',
              header: I18n.t('Shared'),
              cell: (item): CellContents =>
                item.shared ? <Icon name="status-positive" variant="success" /> : '-',
              minWidth: '90px',
              width: 90,
            },
          ],
          [
            'name',
            {
              id: 'name',
              header: I18n.t('Name'),
              cell: (item): CellContents => (
                <NavLink to={`/web/project2/show/${item.uid}`}>{item.name}</NavLink>
              ),
              canSort: true,
              width: 200,
            },
          ],
          [
            'progressStatus',
            {
              id: 'progressStatus',
              header: '',
              alternateName: I18n.t('Warning'),
              cell: (item): CellContents => {
                const dueDateTimeStamp = new Date(item.dateDue).getTime();
                const timeDiff = new Date().getTime() - dueDateTimeStamp;
                return (
                  <Fragment>
                    {item.status !== 'COMPLETED' && dueDateTimeStamp !== 0 && timeDiff > 0 && (
                      <span className="awsui-util-status-negative" style={{ marginRight: 3 }}>
                        <Tooltip content="Overdue">
                          <Icon name="status-pending" />
                        </Tooltip>
                      </span>
                    )}
                    {!item.jobCreationAllowed && item.autoDeletionDateTime && (
                      <span className="awsui-util-status-negative">
                        <Tooltip
                          content={
                            new Date(item.autoDeletionDateTime) > new Date()
                              ? I18n.t('Will move to recycle bin on %{recycleDate}', {
                                  recycleDate: displayTimestamp(item.autoDeletionDateTime),
                                })
                              : I18n.t('Will be sent to the recycle bin soon.')
                          }
                        >
                          <Icon variant="error" name="download" />
                        </Tooltip>
                      </span>
                    )}
                  </Fragment>
                );
              },
              minWidth: '50px',
              width: 50,
              canHide: false,
              canReorder: false,
            },
          ],
          [
            'progress',
            {
              id: 'progress',
              header: I18n.t('Progress'),
              cell: (item): CellContents => (
                <ProgressBar
                  status={
                    item.progress &&
                    item.progress.finishedCount > 0 &&
                    item.progress.finishedCount === item.progress.totalCount
                      ? 'success'
                      : 'in-progress'
                  }
                  value={
                    item.progress && item.progress.finishedCount > 0
                      ? (item.progress.finishedCount / item.progress.totalCount) * 100
                      : 0
                  }
                  resultText={I18n.t('Done')}
                />
              ),
              minWidth: '125px',
              width: 125,
            },
          ],
          [
            'scoring',
            {
              id: 'scoring',
              header: I18n.t('Scoring'),
              cell: (item): CellContents => (
                <ProjectScoringStatus scoringStatus={scoringStatus} groups={item.scoring} />
              ),
              minWidth: '93px',
              width: 93,
              visible: false,
            },
          ],
          [
            'created',
            {
              id: 'created',
              header: I18n.t('Created'),
              cell: (item): CellContents =>
                (item.dateCreated && displayTimestamp(item.dateCreated)) || '-',
              canSort: true,
            },
          ],
          [
            'client',
            {
              id: 'client',
              header: I18n.t('Client'),
              cell: (item): CellContents => (item.client ? item.client.name : '-'),
              canSort: true,
            },
          ],
          [
            'buyer',
            {
              id: 'buyer',
              header: I18n.t('Buyer'),
              cell: (item): CellContents => (item.buyer ? item.buyer.name : '-'),
              canSort: true,
            },
          ],
          [
            'costCenter',
            {
              id: 'costCenter',
              header: I18n.t('Cost center'),
              cell: (item): CellContents => item.costCenter ?? '-',
              canSort: true,
              visible: false,
            },
          ],
          [
            'businessUnit',
            {
              id: 'businessUnit',
              header: I18n.t('Business unit'),
              cell: (item): CellContents => (item.businessUnit ? item.businessUnit.name : '-'),
              visible: false,
              canSort: true,
            },
          ],
          [
            'status',
            {
              id: 'status',
              header: I18n.t('Status'),
              cell: (item): CellContents => statusToDisplay[item.status],
              canSort: true,
            },
          ],
          [
            'dateDue',
            {
              id: 'dateDue',
              header: I18n.t('Due'),
              cell: (item): CellContents =>
                (item.dateDue && displayTimestamp(item.dateDue, true, tz)) || '-',
              canSort: true,
            },
          ],
          [
            'source',
            {
              id: 'source',
              header: I18n.t('Source language'),
              cell: (item): CellContents => formatLocaleCode(item.sourceLang),
              canSort: true,
              visible: false,
            },
          ],
          [
            'target',
            {
              id: 'target',
              header: I18n.t('Target languages'),
              cell: (item): CellContents =>
                item.targetLangs.map(l => formatLocaleCode(l)).join(', '),
            },
          ],
          [
            'note',
            {
              id: 'note',
              header: I18n.t('Note'),
              cell: (item): CellContents =>
                item.note ? (
                  <NoWrapCell>
                    <Tooltip content={item.note}>
                      <Icon name="status-info" variant="link" />
                    </Tooltip>{' '}
                    {item.note}
                  </NoWrapCell>
                ) : (
                  '-'
                ),
              visible: false,
            },
          ],
          [
            'globalId',
            {
              id: 'globalId',
              header: I18n.t('Global id'),
              cell: (item): CellContents => item.id,
              canSort: true,
              visible: false,
            },
          ],
          [
            'uid',
            {
              id: 'uid',
              header: I18n.t('Uid'),
              cell: (item): CellContents => item.uid,
              canSort: true,
              visible: false,
            },
          ],
          [
            'owner',
            {
              id: 'owner',
              header: I18n.t('Owner'),
              cell: (item): CellContents => item.owner.userName,
              canSort: true,
            },
          ],
          [
            'subject',
            {
              id: 'subject',
              header: I18n.t('Subject'),
              cell: (item): CellContents =>
                item.subject
                  ? subjectIdMap.has(item.subject)
                    ? subjectIdMap.get(item.subject)
                    : item.subject
                  : '-',
              visible: false,
            },
          ],
          [
            'mediaType',
            {
              id: 'mediaType',
              header: I18n.t('Media type'),
              cell: (item): CellContents =>
                item.mediaType
                  ? mediaTypeIdMap.has(item.mediaType)
                    ? mediaTypeIdMap.get(item.mediaType)
                    : item.mediaType
                  : '-',
              visible: false,
            },
          ],
          [
            'vendors',
            {
              id: 'vendors',
              header: I18n.t('Vendors'),
              cell: (item): CellContents => (item.vendorNames ? item.vendorNames.join(', ') : '-'),
              visible: false,
            },
          ],
        ]),
      [mediaTypeIdMap, scoringStatus, statusToDisplay, subjectIdMap]
    );

    const filteringMap: Map<string, TablePropertyFilteringOption> = useMemo(
      () =>
        new Map([
          [
            'name',
            {
              propertyKey: 'name',
              propertyLabel: I18n.t('Name'),
              groupValuesLabel: I18n.t('Name'),
              values: [],
            },
          ],
          [
            'sourceLang',
            {
              propertyKey: 'sourceLang',
              propertyLabel: I18n.t('Source language'),
              groupValuesLabel: I18n.t('Source language'),
              values: languageCodeList,
              filterByMultiple: true,
            },
          ],
          [
            'targetLang',
            {
              propertyKey: 'targetLang',
              propertyLabel: I18n.t('Target language'),
              groupValuesLabel: I18n.t('Target language'),
              values: languageCodeList,
              filterByMultiple: true,
            },
          ],
          [
            'status',
            {
              propertyKey: 'status',
              propertyLabel: I18n.t('Status'),
              groupValuesLabel: I18n.t('Status'),
              values: Object.keys(displayToStatus),
              filterByMultiple: true,
            },
          ],
          [
            'businessUnit',
            {
              propertyKey: 'businessUnit',
              propertyLabel: I18n.t('Business unit'),
              groupValuesLabel: I18n.t('Business unit'),
              values: [],
            },
          ],
          [
            'buyer',
            {
              propertyKey: 'buyer',
              propertyLabel: I18n.t('Buyer'),
              groupValuesLabel: I18n.t('Buyer'),
              values: [],
            },
          ],
          [
            'client',
            {
              propertyKey: 'client',
              propertyLabel: I18n.t('Client'),
              groupValuesLabel: I18n.t('Client'),
              values: [],
            },
          ],
          [
            'createdInLastHours',
            {
              propertyKey: 'createdInLastHours',
              propertyLabel: I18n.t('Created in last'),
              groupValuesLabel: I18n.t('Created in last'),
              values: Object.keys(displayToTimeInLastHours),
            },
          ],
          [
            'dueInHours',
            {
              propertyKey: 'dueInHours',
              propertyLabel: I18n.t('Due in'),
              groupValuesLabel: I18n.t('Due in'),
              values: Object.keys(displayToDueInHours),
            },
          ],
          [
            'owner',
            {
              propertyKey: 'owner',
              propertyLabel: I18n.t('Owner'),
              groupValuesLabel: I18n.t('Owner'),
              values: [...ownerUserNameMap.keys()],
            },
          ],
          [
            'subject',
            {
              propertyKey: 'subject',
              propertyLabel: I18n.t('Subject'),
              groupValuesLabel: I18n.t('Subject'),
              values: [...subjectNameMap.keys()],
            },
          ],
          [
            'mediaType',
            {
              propertyKey: 'mediaType',
              propertyLabel: I18n.t('Media type'),
              groupValuesLabel: I18n.t('Media type'),
              values: [...mediaTypeNameMap.keys()],
            },
          ],
          [
            'autoDeletionInHours',
            {
              propertyKey: 'autoDeletionInHours',
              propertyLabel: I18n.t('Auto recycle in'),
              groupValuesLabel: I18n.t('Auto recycle in'),
              values: Object.keys(displayToTimeInLastHours),
            },
          ],
          [
            'vendors',
            {
              propertyKey: 'vendors',
              propertyLabel: I18n.t('Vendor'),
              groupValuesLabel: I18n.t('Vendor'),
              values: [...vendorNameMap.keys()],
              filterByMultiple: true,
            },
          ],
        ]),
      [
        displayToStatus,
        languageCodeList,
        mediaTypeNameMap,
        ownerUserNameMap,
        subjectNameMap,
        vendorNameMap,
      ]
    );

    const transformFilterValue = useCallback(
      (key: string, value): any => {
        if (key === 'owner') {
          if (value === 'myself' && user) {
            return user.id;
          }
          return ownerUserNameMap.get(value) ?? value;
        } else if (key === 'dueInHours') {
          return displayToDueInHours[value];
        } else if (key === 'createdInLastHours') {
          return displayToTimeInLastHours[value];
        } else if (key === 'autoDeletionInHours') {
          return displayToTimeInLastHours[value];
        } else if (key === 'status') {
          return displayToStatus[value];
        } else if (key === 'subject') {
          return subjectNameMap.get(value) ?? value;
        } else if (key === 'mediaType') {
          return mediaTypeNameMap.get(value) ?? value;
        } else if (key === 'vendors') {
          return vendorNameMap.get(value) ?? value;
        }
        return value;
      },
      [displayToStatus, mediaTypeNameMap, ownerUserNameMap, subjectNameMap, user, vendorNameMap]
    );

    const untransformFilterValue = useCallback(
      (key: string, value): any => {
        if (key === 'owner') {
          return ownerIdMap.get(value) ?? value;
        } else if (key === 'dueInHours') {
          return dueInHoursToDisplay[value];
        } else if (key === 'createdInLastHours') {
          return timeInHoursToDisplay[value];
        } else if (key === 'autoDeletionInHours') {
          return timeInHoursToDisplay[value];
        } else if (key === 'status') {
          return statusToDisplay[value];
        } else if (key === 'subject') {
          return subjectIdMap.get(value) ?? value;
        } else if (key === 'mediaType') {
          return mediaTypeIdMap.get(value) ?? value;
        } else if (key === 'vendors') {
          return vendorIdMap.get(value) ?? value;
        }
        return value;
      },
      [mediaTypeIdMap, ownerIdMap, statusToDisplay, subjectIdMap, vendorIdMap]
    );

    const defaultProjectType = {
      id: 'regular-projects',
      value: 'regular-projects',
      label: I18n.t('Regular Projects'),
    };
    const postMortemScoringProjectType = {
      id: 'post-mortem-scoring-projects',
      value: 'post-mortem-scoring-projects',
      label: I18n.t('Sampled Post-Delivery QA'),
    };
    let projectType = defaultProjectType;

    if (isPostMortemScoring === 'true') {
      projectType = postMortemScoringProjectType;
    }

    const projectTypeOptions = [defaultProjectType, postMortemScoringProjectType];

    return (
      <Fragment>
        <PageHeader
          title={I18n.t('Projects')}
          extraContent={<HelpInfoLink helpId={PROJECT_LIST_HELP} />}
          buttons={
            canCreate
              ? [
                  {
                    id: 'new',
                    text: I18n.t('Create project'),
                    variant: 'primary',
                    onClick: (): void => history.push('/web/project/create'),
                  },
                ]
              : []
          }
        />
        {error && <Alert type="error" content={error} />}
        {failedStatusChange && (
          <Alert
            type="error"
            content={I18n.t('Failed to change the status of %{projectCount} projects', {
              projectCount: failedStatusChange.projectIds.length,
            })}
            buttonText={I18n.t('Retry')}
            onButtonClick={(): void => {
              handleChangeStatus(failedStatusChange.projectIds, failedStatusChange.status);
            }}
          />
        )}
        {hasPostMortemScoring && (
          <div className={formSpacing}>
            <h2>
              {I18n.t('Viewing')}:
              <div className={selectSpacing}>
                <Select
                  id="change-post-mortem-scoring"
                  options={projectTypeOptions}
                  selectedOption={projectType}
                  onChange={handleViewProjectTypeChange}
                />
              </div>
            </h2>
          </div>
        )}
        <Container withGutters={false}>
          <AtmsTable<FilteringQueryApi, NonFilteringQueryApiBase, MainState>
            atmsTableId="projectList"
            history={history}
            location={location}
            mainState={state}
            mainDispatch={dispatch}
            stickyHeader={true}
            features={
              canEdit || canDelete
                ? ['propertyFiltering', 'pagination', 'selection', 'sorting']
                : ['propertyFiltering', 'pagination', 'sorting']
            }
            header={
              canEdit || canDelete ? (
                <PageHeader
                  title={I18n.t('Manage projects')}
                  tag="h2"
                  buttons={[
                    canEdit && {
                      id: 'change-status',
                      text: I18n.t('Change status'),
                      disabled: selectedItems.length === 0 || isChangingStatus,
                      loading: isChangingStatus,
                      onItemClick: handleChangeStatusClick,
                      items: [
                        {
                          id: 'change-status-completed',
                          text: I18n.t('Completed'),
                        },
                        {
                          id: 'change-status-cancelled',
                          text: I18n.t('Cancelled'),
                        },
                        {
                          id: 'change-status-new',
                          text: I18n.t('New'),
                        },
                      ],
                    },
                    canEdit &&
                      canShare && {
                        id: 'share',
                        text: I18n.t('Share'),
                        disabled: selectedItems.length === 0,
                        onClick: handleShareButtonClick,
                      },
                    canDelete && {
                      id: 'delete',
                      text: I18n.t('Delete'),
                      disabled: selectedItems.length === 0,
                      onClick: handleDeleteClick,
                    },
                  ]}
                />
              ) : (
                undefined
              )
            }
            role={role}
            columnMapDefault={columnMap}
            columnOrderDefault={getRoleInfo(role).columnOrder}
            filteringMapDefault={filteringMap}
            filteringOrderDefault={getRoleInfo(role).filteringOrder}
            filteringDefaultPropertyKey="name"
            transformFilterValue={transformFilterValue}
            untransformFilterValue={untransformFilterValue}
            filteringPlaceholder={I18n.t('Click to filter projects by property values')}
            wrapLines={true}
            empty={I18n.t('No projects found')}
            noMatch={I18n.t('No matching projects found')}
            saveFilters={saveFilters}
            isOpenEndedPagination={openEndedPagination}
            onChangeOpenEndedPagination={(newPaginationType): void => {
              setOpenEndedPagination(newPaginationType);
            }}
          />
        </Container>

        <StandardModal
          id="projectListDeleteModal"
          header={I18n.t(
            {
              one: 'Delete project?',
              other: 'Delete projects?',
            },
            {
              count: selectedItems.length,
            }
          )}
          content={I18n.t(
            {
              one: 'Do you want to delete %{item}?',
              other: 'Do you want to delete %{count} projects?',
            },
            {
              count: selectedItems.length,
              item: selectedItems.length > 0 && selectedItems[0].name,
            }
          )}
          okButtonId="projectListDeleteModalConfirm"
          visible={showDeleteModal}
          handleCancelClick={handleDeleteModalCancelButtonClick}
          handleOkClick={handleDeleteModalOkButtonClick}
        />
      </Fragment>
    );
  }
);
